import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2021-10-05-schoolnik/body-landing"
import SeriousTargets from "./../screens/webinar-replay/serious-targets"

const SchoolnikView = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Advances in PCR for POCT to Address the Sexually Transmitted Infection Epidemic"
				videoDisplayTitle="Advances in PCR for POCT to Address the Sexually Transmitted Infection Epidemic"
				vimeoLink="https://player.vimeo.com/video/639977538?h=8a153b94c7"
				placeholderImage="2021-10-05-schoolnik-webinar-replay.jpg"
				localStorageID="2021-10-05-schoolnik-webinar-replay"
				pageSlug="/webinar-replay/2021-10-05-schoolnik/"
			/>
			<BodyLanding pageName="download" localStorageID="2021-10-05-schoolnik-webinar-replay" />
			<SeriousTargets />
		</React.Fragment>
	)
}
export default SchoolnikView

export const Head = () => (
  <Seo
        title="Webinar Replay: Advances in PCR for POCT to Address the STI Infection Epidemic"
        description="Presenter Dr. Gary Schoolnik talks about Advances in PCR for POCT to Address the Sexually Transmitted Infection Epidemic."
        image="/meta/webinar-schoolnik-2021-10-05.jpg"
  />
)
